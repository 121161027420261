// Social sharing tools.

.share__fb-tw {
  span {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .st_facebook_large {
    // display: none;
    .stLarge {
      background-image: url('/sites/all/themes/bsi_omega/images/social/FB-f-Logo__blue_512.png') !important;
      width: 100px;
      height: 100px;
      &:hover {
        background-position: -100px;
      }
    }
  }
  .pane-title,
  .st_twitter_large {
    display: none;
  }
  .st_email_large {
    display: none;
  }
  .st_sharethis_large {
    display: none;
  }
}
.share__fb-tw-empty {
  .st_facebook_large,
  .st_twitter_large {
    display: none;
  }
}
.french__tweet {
  p a {
    display: inline-block;
    text-align: center;
    img {
      padding: 0;
      margin: 0 auto;
      margin-bottom: 0 !important;
      float: none !important;
    }
  }
  p {
    margin-bottom: 0;
  }
  padding-bottom: 60px;
}
p a .no__border {
  border: 0 !important;
}
h3.pane-title--blue {
  font: 16px/22px AvenirLTStd-Roman, sans-serif;
  color: #1e5f88;
  text-transform: uppercase;
  border-top: 2px solid #ede295;
  margin-bottom: 10px;
  padding-top: 5px;
  font-weight: bold;
}


// Share links on blogs and announcements.

.node-type-blog-post,
.node-type-announcement{
  .pane-sharethis-sharethis-block{
    margin-top: 14px;
  }
}