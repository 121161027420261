// Alert.

#zone-postscript .block__site-alert{
	width: 100%;
	position: fixed;
	z-index: 9999;
  bottom: 0;
  left: 0;
	color: #fff;
  background: #ede295;
	// height: 50vh;
  @include breakpoint($screen-md) {
  	// height: 30vh;
  }
  @include breakpoint($screen-md) {
	  .flex-container {
		  padding: 0;
		  margin: 0;
		  list-style: none;  
		  display: -webkit-box;
		  display: -moz-box;
		  display: -ms-flexbox;
		  display: -webkit-flex;
		  display: flex;
		  align-items: center;
		  justify-content: center;
		}
  }
  .content{  
  	width: 80%;
  	color: #fff;
  	margin: 0 auto;
  	padding: 30px;
    @include breakpoint($screen-md) {
    	padding: 10px;
    }
	  .block__site-alert__toggle{
	  	float: right;
	  	font-size: 22px;
	  	margin-right: 10px;
	  	margin-top: 10px;
			&:hover{
	      cursor: pointer;				
			}
	  }
	  h1{
  		color: #75863C;
	    font: 22px/26px AvenirLTStd-Roman,sans-serif;
	    letter-spacing: .03em;
      @include breakpoint($screen-md) {
	      font: 42px/56px AvenirLTStd-Roman,sans-serif;
	    }
	    text-align: center;
	    text-transform: uppercase;
	    margin-bottom: 2px;
	  }
	  input{
	  	border: 1px solid #e1e3e4;
	  	border: 0;
	  	margin-right: 20px;
	  	font-size: 20px;
      box-shadow: 0;
      margin-bottom: 8px;
      padding-left: 15px;
      &::placeholder{
  			text-transform: uppercase;
  			font-size: 16px;
  			margin-left: 10px;
      }
	  }
		input[type="submit"]{
			text-transform: uppercase;
			border-radius: 0;
			border: 2px solid #F6F1CA;
			background-color: #ebd95a;
			background-image: none;
			padding: 9px 10px 5px;
			box-shadow: 0 0 6px #cccccc;
			color: #1E5F88;
		}
		input[type="submit"]:hover {
			color: #2c80b5;
			background-color: #f6e676;
		}
	  p{
	  	font-size: 16px;
      @include breakpoint($screen-md) {
  	  	font-size: 22px;
	  	}
	  	text-align: center;
	  	line-height: 26px;
	  	margin-bottom: 20px;
	  	color: #555;
	  }
	  a{
    	background-color: #ebd95a;
    	border: 2px solid white;
			text-align: center;
			padding: 14px 20px 12px;
			font-size: 16px;
			line-height: 18px;
			text-transform: uppercase;
			color: #1E5F88;
			text-decoration: none;
			font-family: AvenirLTStd-Heavy;
			display: inline-block;
			&:hover{
      	background-color: #f6e676;
	      color: #2c80b5;				
			}
	  }
  }
}